<template>
  <div class="content d-flex flex-column flex-column-fluid">
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Card-->
        <div class="card card-custom gutter-b">
          <!--begin::Body-->
          <div class="card-body p-0">
            <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
              <div class="col-xl-12 col-xxl-7">
                <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                  <b-form @submit.prevent="handleSubmit(createUser)">
                    <h3 class="mb-10 font-weight-bold text-dark">
                      Client Account Details:
                    </h3>
                    <div class="row">
                      <div class="col-xl-12">
                        <b-form-group label-cols-sm="3" id="input-group-1" label="Name" label-for="input-1">
                          <b-form-input
                            v-model="name"
                            id="input-1"
                            type="text"
                            name="name"
                            class="form-control form-control-lg form-control-solid"
                          >
                          </b-form-input>
                        </b-form-group>

                        <validation-provider rules="required|email" name="email" v-slot="{ valid, errors }">
                          <b-form-group
                            label-cols-sm="3"
                            id="input-group-1"
                            label="Email"
                            label-for="input-2"
                            description="Email will be used as login username"
                          >
                            <b-input-group size="lg">
                              <b-input-group-prepend>
                                <span class="input-group-text">
                                  <i class="la la-at"></i>
                                </span>
                              </b-input-group-prepend>
                              <b-form-input
                                v-model="username"
                                id="input-2"
                                type="text"
                                class="form-control form-control-lg form-control-solid"
                                name="email"
                                :state="errors[0] ? false : valid ? true : null"
                              ></b-form-input>
                              <b-form-invalid-feedback id="inputLiveFeedback">
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </b-input-group>
                          </b-form-group>
                        </validation-provider>

                        <b-form-group label-cols-sm="3" id="input-group-1" label="Phone" label-for="input-3">
                          <b-input-group size="lg">
                            <b-input-group-prepend>
                              <span class="input-group-text">
                                <i class="la la-phone"></i>
                              </span>
                            </b-input-group-prepend>
                            <b-form-input
                              v-model="mobile"
                              id="input-3"
                              type="text"
                              class="form-control form-control-lg form-control-solid"
                              name="phone"
                            ></b-form-input>
                          </b-input-group>
                        </b-form-group>
                      </div>
                    </div>

                    <div class="d-flex justify-content-between border-top pt-10">
                      <!-- <button type="submit" class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4">
                        Submit
                      </button> -->
                      <submit-button :loading="loading" />
                    </div>
                  </b-form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { updateClientUser } from "@/api/client.api";
import { useNotify } from "@/utils/notify";

import { ref, reactive, toRefs } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  name: "updateClientUser",
  components: { ValidationProvider, ValidationObserver },
  props: {
    userObject: Object
  },
  setup(props, context) {
    const { notify } = useNotify(context);
    const companyId = context.root.$route.params.company;

    const { id, name, username, mobile } = toRefs(reactive(props.userObject));

    const loading = ref(false);

    const createUser = async function() {
      loading.value = true;
      const payload = {
        name: name.value,
        username: username.value,
        mobile: mobile.value
      };
      try {
        await updateClientUser(id.value, companyId, payload);
        notify("Account updated", `The account is updated`);
        context.root.$router.push({ name: "ListClientCompanyUser", params: { id: companyId } });
      } catch (e) {
        console.error(e);
      }

      loading.value = false;
    };

    return {
      name,
      username,
      mobile,

      createUser,
      loading
    };
  }
};
</script>
